import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { GSAdminLocale } from '@app/locales/localeid';
import { withRouter, ResourceOperation, PaginationParams, GLUtil, GLGlobal } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { lazyInject, TYPES, DateHelper, SchoolHelper } from '@app/util/index';
import { SchoolLicenseModel, SchoolLicensePagingRequest, ISchoolLicenseService, LicenseBillingHistoryModel } from '@app/service/admin/license/index';
import { ISchoolClassService } from '@app/service/class/index';
import { ISchoolService } from '@app/service/schools/index';
import { setBreadcrumbs } from '@app/states/resource';
import { MainTitle } from '@app/components';
import { LicenseHistoryTableComponent, HistoryTableProps } from './components';

interface LicensePageProps extends RouteComponentProps<any> {
    pagination?: PaginationParams
}
interface LicensePageStates {
    loading?: boolean
    resCmd?: ResourceOperation
    schoolName?: string
    regionBillingHistoryId?: string
    //  isLatestBilling?: boolean
    billingPeriod?: string
}

@withRouter
export class SchoolLicenseHistoryPage extends Component<LicensePageProps, LicensePageStates> {
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService
    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService
    schoolLicenseList?: SchoolLicenseModel[] = []
    pagination: PaginationParams = new PaginationParams(1, 20)
    tableProps: HistoryTableProps = {
        handleTablePageChange: this.handleTablePageChange.bind(this),
        canEdit: false
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            schoolName: '',
            billingPeriod: '',
        };
    }

    componentDidMount() {
        const { schoolId, historyId } = GLUtil.pathParse(PathConfig.SchoolLicenseHistory);
        let actions = [];
        actions.push(this.schoolService.getSchool({ id: schoolId }));
        const params = {
            pagingParams: new SchoolLicensePagingRequest(this.pagination),
            routeParams: {
                schoolId,
                regionInvoiceHistoryId: historyId
            }
        }
        actions.push(this.licenseService.getSchoolLicenseHistoryForInvoicePeriod(params));
        this.setTableLoading();
        Promise.all(actions).then((res) => {
            const [school, licenseData] = res;
            const validData = licenseData.data;
            const billingPeriod = validData.invoiceStartDate && validData.invoiceEndDate
                ? DateHelper.formatDate2Local(validData.invoiceStartDate) + " ~ " + DateHelper.formatDate2Local(validData.invoiceEndDate)
                : "";
            setBreadcrumbs({
                history: {
                    id: this.props.match.params.historyId,
                    name: billingPeriod
                }
            });
            this.pagination.total = licenseData.totalCount;
            this.tableProps.pagination = this.pagination;
            this.tableProps.dataSource = this.formatSchoolLicenseList(validData.licenseBillingHistory);
            this.setState({
                schoolName: school.name,
                billingPeriod: `(${billingPeriod})`,
            });
            this.setTableLoading(false);
        }).catch((error) => {
            this.setTableLoading(false);
        });
    }
    handleTablePageChange(pagination: any) {
        this.pagination.current = pagination.current;
        this.setSchoolLicenseList();
    }

    formatSchoolLicenseList = (list: LicenseBillingHistoryModel[]): LicenseBillingHistoryModel[] => {
        const schoolCurriculumType = SchoolHelper.generateSchoolCurriculumType();
        return Array.isArray(list) ? list.map((item, index) => {
            item.licenseType = schoolCurriculumType.get(item.licenseTypeId.toString());
            return item
        })
        : []
    }

    setSchoolLicenseList() {
        const { schoolId, historyId } = GLUtil.pathParse(PathConfig.SchoolLicenseHistory);
        const params = {
            pagingParams: new SchoolLicensePagingRequest(this.pagination),
            routeParams: {
                schoolId,
                regionInvoiceHistoryId: historyId
            }
        }
        this.setTableLoading();
        this.licenseService.getSchoolLicenseHistoryForInvoicePeriod(params).then(data => {
            const validData = data.data;
            this.pagination.total = data.totalCount;
            this.tableProps.pagination = this.pagination;
            this.tableProps.dataSource = this.formatSchoolLicenseList(validData.licenseBillingHistory);
            this.setTableLoading(false);
        }).catch((error) => {
            this.setTableLoading(false);
        });
    }

    setTableLoading(isLoading: boolean = true) {
        this.tableProps.loading = isLoading;
        this.setState({ loading: isLoading });
    }
    render() {
        return (
            <div className='content-layout history-list'>
                <MainTitle plain={`${GLGlobal.intl.formatMessage({ id: GSAdminLocale.SchoolLicenseTitle })} ${this.state.billingPeriod}`} />
                <div className='page-content'>
                    <LicenseHistoryTableComponent {...this.tableProps} history={this.props.history} loading={this.state.loading}/>
                </div>
            </div>
        );
    }
}